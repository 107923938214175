<template>
  <div class="goods-list">
    <div class="table-title">
      <p class="total">共{{ total }}条</p>
    </div>
    <div class="table-menu">
      <!--  <div class="table-menu-right">
        <a-input-search
          placeholder="请输入店铺关键字"
          @search="onSearch"
          v-model="keyword"
          enterButton="查找"
          style="width:100%"
          >查找</a-input-search
        >
      </div> -->
      <a-button type="primary" @click="showModal" class="senior-search">添加供应商</a-button>
    </div>
    <div class="my-table">
      <a-table :columns="columns" :loading="loading" :dataSource="data" align="center" rowKey="id" :pagination="false">
        <template slot="nickname" slot-scope="name, record">
          <div class="price-box" style="display: flex;">
            <div>
              <p>{{ record.nickname }}</p>
            </div>
          </div>
        </template>
        <template slot="phone" slot-scope="phone, record">
          <div class="price-box" style="display: flex;">
            <div>
              <p>{{ record.phone }}</p>
            </div>
          </div>
        </template>
        <template slot="storeMobile" slot-scope="storeMobile, record">
          <div class="price-box" style="display: flex;">
            <div>
              <p>{{ record.storeMobile }}</p>
            </div>
          </div>
        </template>
        <template slot="storeName" slot-scope="store, record">
          <div class="price-box" style="display: flex;">
            <div>
              <p>{{ record.storeName }}</p>
            </div>
          </div>
        </template>
        <template slot="storeRemark" slot-scope="storeRemark, record">
          <div class="price-box" style="display: flex;">
            <div>
              <p>{{ record.storeRemark }}</p>
            </div>
          </div>
        </template>
        <span slot="action" slot-scope="action, record">
          <a href="javascript:;" @click="DeletePro(record)">删除</a>
        </span>
      </a-table>
      <div style="text-align: right;" class="mt10 mb10 mr10">
        <a-pagination v-model="current" :total="total" :defaultPageSize="10" @change="pageChange" />
      </div>
    </div>
    <a-modal
      title="添加供应商"
      :visible="storeVisible"
      @ok="handleSubmit"
      @cancel="cancelStore"
      ok-text="确认"
      cancel-text="取消"
      width="480px"
    >
      <a-form :form="form" @submit="handleSubmit" labelAlign="left">
        <a-form-item v-bind="formItemLayout" label="姓名">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入姓名'
                  }
                ]
              }
            ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="供应商编号" has-feedback>
          <a-input
            v-decorator="[
              'phone',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入供应商编号'
                  }
                ]
              }
            ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="手机号" has-feedback>
          <a-input
            v-decorator="[
              'storeMobile',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入手机号'
                  }
                ]
              }
            ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="店铺名" has-feedback>
          <a-input
            v-decorator="[
              'storeName',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入店铺名'
                  }
                ]
              }
            ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="备注" has-feedback>
          <a-input v-decorator="['storeRemark']" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
// importUser
import { getStoreList, getStoreLength, createStore, deleteStore } from '../../serve/member'
const columns = [
  {
    dataIndex: 'nickname',
    key: 'nickname',
    title: '姓名',
    scopedSlots: { customRender: 'nickname' }
  },
  {
    title: '供应商编号',
    dataIndex: 'phone',
    scopedSlots: { customRender: 'phone' }
  },
  {
    title: '手机号',
    dataIndex: 'storeMobile',
    scopedSlots: { customRender: 'storeMobile' }
  },
  {
    title: '店铺',
    dataIndex: 'storeName',
    scopedSlots: { customRender: 'storeName' }
  },
  {
    title: '备注',
    dataIndex: 'storeRemark',
    scopedSlots: { customRender: 'storeRemark' }
  }
  /* {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  } */
]
export default {
  name: 'vipList',
  data () {
    return {
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      },
      form: this.$form.createForm(this),
      storeVisible: false,
      data: [],
      columns,
      res: [],
      loading: false, // 加载状态
      responseId: '',
      fileName: '',
      isDisabled: false,
      schoolVal: '',
      keyword: '',
      total: 0,
      current: 1,
      params: {
        page: 0,
        rows: 20
      }
    }
  },
  methods: {
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          createStore(0, values)
            .then(() => {
              this.$message.success('创建成功！')
              this.storeVisible = false
              this.getList('a', this.params)
            })
            .catch(() => {
              this.$message.error('创建失败！')
            })
        }
      })
    },
    cancelStore () {
      this.storeVisible = false
      this.form.resetFields()
    },
    DeletePro (record) {
      let that = this
      this.$confirm({
        title: '提示',
        content: '确认删除该店铺?',
        okText: '确定',
        cancelText: '取消',
        onOk () {
          deleteStore(record.storeId, record.id)
            .then(() => {
              that.$message.success('删除成功！')
              that.form.resetFields()
              that.getList('a', that.params)
            })
            .catch(() => {
              that.$message.error('删除失败！')
            })
        }
      })
    },
    showModal () {
      this.storeVisible = true
    },
    async handleChange (val) {
      this.params.type = val
      this.params.keyword = this.keyword
      this.getList('a', this.params)
    },
    // 获取店铺列表
    async getList (storeId, params) {
      this.loading = true
      this.total = await getStoreLength(storeId)
      this.total > 0 ? (this.data = await getStoreList(storeId, params)) : (this.data = [])
      this.loading = false
    },
    onSearch () {},
    handleOk () {},
    pageChange (page) {
      this.params.page = page - 1
      this.getList('a', this.params)
    }
  },
  created () {
    this.getList('a', this.params)
  }
}
</script>

<style lang="less" scoped>
.goods-list {
  border: 1px solid #cccccc;
  .table-title {
    .title {
      font-weight: 600;
      color: black;
      font-size: 15px;
      margin: 10px 0 10px 10px;
    }
    .total {
      text-align: right;
      margin: 10px 10px 10px 10px;
    }
  }
}
.table-menu {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  padding: 0 20px;
  .senior-search {
  }
  .table-menu-right {
    display: flex;
    .my-input {
      height: 32px !important;
    }
  }
}
.my-table {
  .price-box {
    // display: flex;
    // flex-direction: column;
    .price-show {
      color: red;
    }
  }
}
.editable-row-operations a {
  margin-right: 8px;
}
</style>
<style lang="less">
.table-title {
  .ant-upload-list {
    display: inline-block;
    // width: 100px;
  }
}
</style>
